import type { EventDetective } from "../eventDetective";

import { document } from "../../utils/globals";
import { isNumber } from "../../utils/typeUtils";
import { uuid } from "../../utils/uuid";

const SESSION_ID = "session";

const MAX_SESSION_IDLE_TIMEOUT = 30 * 60; // 30 minutes
const MIN_SESSION_IDLE_TIMEOUT = 60; // 1 minute
const SESSION_LENGTH_LIMIT = 24 * 3600 * 1000; // 24 hours

type SessionIdChangedCallback = (
  sessionId: string,
  windowId: null | string | undefined,
) => void;

export function createSessionIdManager(
  configStore: EventDetective["configStore"],
): {
  checkAndGetSessionAndWindowId: ({
    _timestamp,
    client,
    readOnly,
  }: {
    _timestamp?: null | number;
    client: InstanceType<typeof EventDetective>;
    readOnly?: boolean;
  }) => {
    sessionId: string;
    sessionStartTimestamp: number;
    windowId: string;
  };
  onSessionId: (callback: SessionIdChangedCallback) => () => void;
  resetSessionId: () => void;
} {
  let _sessionIdChangedHandlers: SessionIdChangedCallback[] = [];
  let _windowId: null | string | undefined = undefined;
  let _sessionId: null | string | undefined = undefined;
  let _sessionStartTimestamp: null | number = null;
  let _sessionActivityTimestamp: null | number = null;

  const _sessionTimeoutMs = (() => {
    const desiredTimeout = MAX_SESSION_IDLE_TIMEOUT;
    if (!isNumber(desiredTimeout)) {
      console.warn(
        "session_idle_timeout_seconds must be a number. Defaulting to 30 minutes.",
      );
      return MAX_SESSION_IDLE_TIMEOUT;
    }
    return (
      Math.min(
        Math.max(desiredTimeout, MIN_SESSION_IDLE_TIMEOUT),
        MAX_SESSION_IDLE_TIMEOUT,
      ) * 1000
    );
  })();

  const _setWindowId = (windowId: string): void => {
    if (windowId !== _windowId) {
      _windowId = windowId;
    }
  };

  const _getSessionId = (): [number, null | string, number] => {
    if (_sessionId && _sessionActivityTimestamp && _sessionStartTimestamp) {
      return [_sessionActivityTimestamp, _sessionId, _sessionStartTimestamp];
    }
    return (
      (configStore.getValue(SESSION_ID) as [number, null | string, number]) || [
        0,
        null,
        0,
      ]
    );
  };

  const resetSessionId = (): void => {
    _setSessionId(null, null, null);
  };

  const onSessionId = (callback: SessionIdChangedCallback): (() => void) => {
    _sessionIdChangedHandlers.push(callback);
    if (_sessionId) {
      callback(_sessionId, _windowId);
    }
    return () => {
      _sessionIdChangedHandlers = _sessionIdChangedHandlers.filter(
        (h) => h !== callback,
      );
    };
  };

  const _setSessionId = (
    sessionId: null | string,
    sessionActivityTimestamp: null | number,
    sessionStartTimestamp: null | number,
  ): void => {
    if (
      sessionId !== _sessionId ||
      sessionActivityTimestamp !== _sessionActivityTimestamp ||
      sessionStartTimestamp !== _sessionStartTimestamp
    ) {
      _sessionStartTimestamp = sessionStartTimestamp;
      _sessionActivityTimestamp = sessionActivityTimestamp;
      _sessionId = sessionId;

      configStore.setValue(SESSION_ID, [
        sessionActivityTimestamp,
        sessionId,
        sessionStartTimestamp,
      ]);
    }
  };

  const checkAndGetSessionAndWindowId = ({
    _timestamp,
    client,
    readOnly = false,
  }: {
    _timestamp?: null | number;
    client: InstanceType<typeof EventDetective>;
    readOnly?: boolean;
  }): {
    sessionId: string;
    sessionStartTimestamp: number;
    windowId: string;
  } => {
    const timestamp = _timestamp || Date.now();
    let [lastTimestamp, sessionId, startTimestamp] = _getSessionId();
    let windowId = _windowId || uuid();

    const sessionPastMaximumLength =
      startTimestamp > 0 &&
      Math.abs(timestamp - startTimestamp) > SESSION_LENGTH_LIMIT;

    let valuesChanged = false;
    const noSessionId = !sessionId;
    const activityTimeout =
      !readOnly && Math.abs(timestamp - lastTimestamp) > _sessionTimeoutMs;
    if (
      (noSessionId || activityTimeout || sessionPastMaximumLength) &&
      document
    ) {
      sessionId = uuid();
      windowId = uuid();

      client.logDebug("new session ID generated", {
        changeReason: {
          activityTimeout,
          noSessionId,
          sessionPastMaximumLength,
        },
        sessionId,
        windowId,
      });

      startTimestamp = timestamp;
      valuesChanged = true;
    }

    const newTimestamp =
      lastTimestamp === 0 || !readOnly || sessionPastMaximumLength
        ? timestamp
        : lastTimestamp;
    const sessionStartTimestamp =
      startTimestamp === 0 ? Date.now() : startTimestamp;

    _setWindowId(windowId);
    _setSessionId(sessionId, newTimestamp, sessionStartTimestamp);

    if (valuesChanged) {
      _sessionIdChangedHandlers.forEach((handler) =>
        handler(sessionId as string, windowId),
      );
    }

    return { sessionId: sessionId as string, sessionStartTimestamp, windowId };
  };

  return { checkAndGetSessionAndWindowId, onSessionId, resetSessionId };
}
