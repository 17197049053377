import { useContext } from "react";

import { EventDetectiveContext } from "../provider";
import { actionEventAlias } from "../reducer/actions/alias";
import { actionEventCommon } from "../reducer/actions/common";
import { actionEventConsent } from "../reducer/actions/consent";
import { actionEventGroup } from "../reducer/actions/group";
import { actionEventIdentify } from "../reducer/actions/identify";
import { actionEventPage } from "../reducer/actions/page";
import { actionEventScreen } from "../reducer/actions/screen";
import { actionEventTrack } from "../reducer/actions/track";

export const useAnalytics = () => {
  const context = useContext(EventDetectiveContext);

  return {
    alias: (payload: actionEventAlias) => context?.client.alias(payload),
    common: (payload: actionEventCommon) => context?.client.common(payload),
    consent: (payload: actionEventConsent) => context?.client.consent(payload),
    group: (payload: actionEventGroup) => context?.client.group(payload),
    identify: (payload: actionEventIdentify) =>
      context?.client.identify(payload),
    isLoaded: context?.loaded,
    page: (payload: actionEventPage) => context?.client.page(payload),
    reset: () => context?.client.reset(),
    screen: (payload: actionEventScreen) => context?.client.screen(payload),
    state: context?.state,
    track: (payload: actionEventTrack) => context?.client.track(payload),
  };
};
