import type { EventOptions, EventTypes } from "@everfund/event-sidekick";

import { EventDetective } from "../eventDetective";
import { getPartialEvent } from "../getPartialEvent";
import { logEvent } from "../queue/logEvent";

export function actionScreen(
  client: InstanceType<typeof EventDetective>,
  action: actionEventScreen,
) {
  const { category, options, props, screen } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      name: screen,
      properties: {
        ...partialEvent.context.page,
        ...partialEvent.properties,
        ...props,
        category,
      },
      type: "screen",
    },
  });
  return client.state;
}

export type actionEventScreen = {
  category?: string;
  options?: EventOptions;
  props?: EventTypes["page"];
  screen?: string;
};

export const defaultActionScreen: actionEventScreen = {
  category: "one",
  screen: "page one",
};
