import type { Event, EventOptions } from "@everfund/event-sidekick";

import {
  document,
  location,
  navigator,
  userAgent,
  window,
} from "../../utils/globals";
import { uuid } from "../../utils/uuid";
import { EventDetective } from "../eventDetective";
import { getAMP } from "./getAmp";
import { getBrowser } from "./getBrowser";
import { getBrowserVersion } from "./getBrowserVersion";
import { getCampaign } from "./getCampaign";
import { getDevice } from "./getDevice";
import { getOS } from "./getOS";
import { getReferrer } from "./getReferrer";

type getPartialEventProps = {
  client: InstanceType<typeof EventDetective>;
  options?: Exclude<EventOptions, "priority">;
};

export function getPartialEvent({
  client,
  options,
}: getPartialEventProps): Omit<Event, "event" | "type"> {
  let event: Omit<Event, "event" | "type"> = {
    anonymousId: options?.anonymousId ?? client.state.anonymousId,
    context: {
      ...options?.context,
      groupId: client.state.groupId,
      traits: client.state.userTraits,
    },
    messageId: uuid(),
    properties: {
      ...client.state.commonProperties,
    },
    sessionId: (options?.sessionId as string) ?? client.state.sessionId,
    timestamp: options?.timestamp ?? new Date(),
    userId: options?.userId ?? client.state.userId,
    windowId: (options?.windowId as string) ?? client.state.windowId,
  };

  if (window && navigator) {
    const referrer = event.context.referrer ?? getReferrer(client);
    const campaign = event.context.campaign ?? getCampaign(client.state);
    const amp = event.context.amp ?? getAMP(client.state);

    event = {
      ...event,
      context: {
        amp,
        browser:
          userAgent &&
          getBrowser(userAgent, navigator.vendor, !!(window as any).opera),
        browser_version: userAgent
          ? getBrowserVersion(
              userAgent,
              navigator.vendor,
              !!(window as any).opera,
            )
          : null,
        campaign,
        current_url: location?.href,
        device: userAgent && getDevice(userAgent),
        host: location?.host,
        locale: navigator.language,
        os: getOS(window),
        os_version: undefined,
        page:
          location && document
            ? {
                path: location.pathname,
                referrer: document.referrer ?? null,
                search: location.search,
                title: document.title,
                url: location.href,
              }
            : undefined,
        pathname: location?.pathname,
        referrer,
        screen_dpr: window.devicePixelRatio,
        screen_height: window.screen.height,
        screen_width: window.screen.width,
        viewport_height: window.innerHeight,
        viewport_width: window.innerWidth,
        ...event.context,
      },
    };

    client.logDebug("getPartialEvent -> added context to event ", event);
    return event;
  }
  client.logDebug("getPartialEvent -> did not context to event ", event);

  return event;
}
