import type { CommonPropType, Referrer } from "@everfund/event-sidekick";

import type { DetectiveProps, DetectiveState, TrackerAction } from "./types";

import { assignableWindow, document } from "../utils/globals";
import { uuid } from "../utils/uuid";
import { actionAlias, type actionEventAlias } from "./actions/alias";
import { actionCommon, type actionEventCommon } from "./actions/common";
import { COMMON_PROPERTIES_KEY } from "./actions/common";
import {
  actionConsent,
  type actionEventConsent,
  consentObject,
} from "./actions/consent";
import { CONSENT_KEY } from "./actions/consent";
import { type actionEventGroup, actionGroup } from "./actions/group";
import { GROUP_ID_KEY, GROUP_TRAITS_KEY } from "./actions/group";
import { type actionEventIdentify, actionIdentify } from "./actions/identify";
import {
  ANONYMOUS_ID_KEY,
  USER_ID_KEY,
  USER_TRAITS_KEY,
} from "./actions/identify";
import { type actionEventPage, actionPage } from "./actions/page";
import { actionReset } from "./actions/reset";
import { type actionEventScreen, actionScreen } from "./actions/screen";
import { type actionEventTrack, actionTrack } from "./actions/track";
import { configStore } from "./configStore";
import { REFERRER_KEY } from "./getPartialEvent/getReferrer";
import { createSessionIdManager } from "./session";

export const DEFAULT_EVENT_DEBOUNCE_INTERVAL = 300;
export const DEFAULT_SIDEKICK_URL = "/api/detective";

export class EventDetective {
  private static instance: EventDetective | null | undefined;
  #listeners: Array<(emission: EventDetective["state"]) => void> = [];
  public addListener = (listener: (updatedState: DetectiveState) => void) => {
    this.#listeners.push(listener);

    listener(this.state);
    this.logDebug("Listener added", { listenerCount: this.#listeners.length });

    type MethodType = (
      this: EventDetective,
      ...args: TrackerAction[]
    ) => DetectiveState;

    const originalMethods = {
      alias: this.alias,
      common: this.common,
      consent: this.consent,
      group: this.group,
      identify: this.identify,
      page: this.page,
      reset: this.reset,
      screen: this.screen,
      track: this.track,
    };

    Object.entries(originalMethods).forEach(([key, method]) => {
      this[key as keyof typeof originalMethods] = function (
        this: EventDetective,
        ...args: TrackerAction[]
      ) {
        const updatedState = (method as MethodType).apply(this, args);
        this.#listeners.forEach((l) => l(updatedState));
        return updatedState;
      };
    });

    const unsubscribe = () => {
      this.#listeners = this.#listeners.filter((l) => l !== listener);
    };

    return unsubscribe;
  };
  public configStore: typeof configStore;
  public loaded: boolean = false;

  public loadedListeners: Array<() => void> = [];

  public readonly options: DetectiveProps;

  public state: DetectiveState;

  public constructor(options: DetectiveProps) {
    this.options = options;
    this.configStore = configStore;
    this.state = this.initializeState();
    this.exposeToWindow();
    this.logDebug("EventDetective initialized", this.options);
  }

  static clearInstance(): void {
    this.instance = null;
  }

  static getOrCreateInstance(options: DetectiveProps): EventDetective {
    if (!document || !this.instance) {
      this.instance = new EventDetective(options);
    }
    return this.instance;
  }

  public addOnLoaded(cb: () => void): void {
    this.loadedListeners.push(cb);
    if (this.isLoaded()) {
      this.emitLoaded();
    }
  }

  public alias(action: actionEventAlias) {
    this.logDebug("Alias action called", action);
    return actionAlias(this, action);
  }

  public common(action: actionEventCommon) {
    this.logDebug("Common action called", action);
    return actionCommon(this, action);
  }

  public consent(action: actionEventConsent) {
    this.logDebug("Consent action called", action);
    return actionConsent(this, action);
  }

  public emitLoaded(): void {
    this.loadedListeners.forEach((cb) => cb());
    this.loadedListeners = [];
  }

  public exposeToWindow(): void {
    if (typeof window !== "undefined") {
      this.setLoaded();
      assignableWindow["eventDetectiveTracker"] = {
        alias: this.alias.bind(this),
        configStore: this.configStore,
        consent: this.consent.bind(this),
        group: this.group.bind(this),
        identify: this.identify.bind(this),
        loaded: this.loaded,
        options: {
          ...this.options,
          eventDebounceInterval:
            this.options?.eventDebounceInterval ??
            DEFAULT_EVENT_DEBOUNCE_INTERVAL,
          url: this.options?.url ?? DEFAULT_SIDEKICK_URL,
        },
        page: this.page.bind(this),
        reset: this.reset.bind(this),
        screen: this.screen.bind(this),
        state: this.state,
        track: this.track.bind(this),
      };
    }
  }

  public generateAnonymousId(): string {
    const anonymousUUID = uuid();
    this.configStore.setValue(ANONYMOUS_ID_KEY, anonymousUUID);
    return anonymousUUID;
  }

  public getConfigValue<T>(key: string): T | undefined {
    return this.configStore.getValue(key) as T | undefined;
  }

  public getOptions() {
    return this.options;
  }

  public getState() {
    this.logDebug("getState", this.state);
    return this.state;
  }

  public getStore(): typeof configStore {
    return this.configStore;
  }

  public group(action: actionEventGroup) {
    this.logDebug("Group action called", action);
    return actionGroup(this, action);
  }

  public identify(action: actionEventIdentify) {
    this.logDebug("Identify action called", action);
    return actionIdentify(this, action);
  }

  public initializeState() {
    const anonymousId =
      this.getConfigValue<string>(ANONYMOUS_ID_KEY) ||
      this.generateAnonymousId();
    const userId = this.getConfigValue<string>(USER_ID_KEY);
    const userTraits =
      this.getConfigValue<Record<string, CommonPropType>>(USER_TRAITS_KEY) ||
      {};
    const groupId = this.getConfigValue<string>(GROUP_ID_KEY);
    const groupTraits =
      this.getConfigValue<Record<string, CommonPropType>>(GROUP_TRAITS_KEY) ||
      {};
    const commonProperties =
      this.getConfigValue<Record<string, CommonPropType>>(
        COMMON_PROPERTIES_KEY,
      ) || {};
    const referrer = this.getConfigValue<Referrer>(REFERRER_KEY);
    const consent =
      this.getConfigValue<consentObject>(CONSENT_KEY) ||
      ({
        dateConsented: undefined,
        gdprPurposes: undefined,
        origin: undefined,
      } as consentObject);
    const { sessionId, windowId } = createSessionIdManager(
      this.configStore,
    ).checkAndGetSessionAndWindowId({
      client: this,
    });

    return {
      anonymousId,
      commonProperties,
      consent,
      groupId,
      groupTraits,
      referrer,
      sessionId,
      userId,
      userTraits,
      windowId,
    };
  }

  public isLoaded(): boolean {
    return this.loaded;
  }

  public logDebug<T>(message: string, data?: T): void {
    if (this.options.debug) {
      console.log(`[EventDetective Debug] ${message}`, data);
    }
  }

  public page(action: actionEventPage) {
    this.logDebug("Page action called", action);
    return actionPage(this, action);
  }

  public reset() {
    this.logDebug("Reset action called");
    return actionReset(this);
  }

  public screen(action: actionEventScreen) {
    this.logDebug("Screen action called", action);
    return actionScreen(this, action);
  }

  public setLoaded(): void {
    this.logDebug("Loaded event detective tracker");
    this.loaded = true;
  }

  public setState(state: Partial<DetectiveState>): void {
    this.state = { ...this.state, ...state };
    this.logDebug("State updated", this.state);
  }

  public track(action: actionEventTrack) {
    this.logDebug("Track action called", action);
    return actionTrack(this, action);
  }
}
