import type { EventOptions, EventTypes } from "@everfund/event-sidekick";

import { type allConsentNames, consentTypes } from "@everfund/event-gdpr-types";

import { location } from "../../utils/globals";
import { EventDetective } from "../eventDetective";
import { actionPage } from "./page";
import { actionTrack } from "./track";

export const CONSENT_KEY = "const";

const setMarketingConsent = (purposes: Record<allConsentNames, boolean>) => {
  const gdprPurposes: Record<allConsentNames, boolean> = consentTypes.reduce(
    (acc, consent) => {
      if (!purposes[consent.name]) {
        acc[consent.name] = consent.defaultValue;
        return acc;
      }
      acc[consent.name] = purposes[consent.name];
      return acc;
    },
    {} as Record<allConsentNames, boolean>,
  );

  return {
    dateConsented: new Date().toISOString(),
    gdprPurposes,
    origin,
  };
};

export function actionConsent(
  client: InstanceType<typeof EventDetective>,
  action: actionEventConsent,
) {
  const { gdprPurposes, type } = action;

  let consent: Required<consentObject> | undefined;

  switch (type) {
    case "all": {
      consent = setMarketingConsent({
        experience: true,
        functionality: true,
        marketing: true,
        measurement: true,
        necessary: true,
      }) as Required<consentObject>;

      break;
    }

    case "minimum": {
      consent = setMarketingConsent({
        experience: false,
        functionality: false,
        marketing: false,
        measurement: false,
        necessary: true,
      }) as Required<consentObject>;
      break;
    }
    default: {
      consent =
        gdprPurposes &&
        (setMarketingConsent(gdprPurposes) as Required<consentObject>);
    }
  }

  client.configStore.setValue(CONSENT_KEY, consent);

  client.setState({
    consent,
  });

  const pathname = location?.pathname;
  const search = location?.search;

  actionTrack(client, {
    name: "GDPR Consent",
    properties: {
      consent_selected: type,
      consented_to_experience: consent.gdprPurposes.experience,
      consented_to_functionality: consent.gdprPurposes.functionality,
      consented_to_marketing: consent.gdprPurposes.marketing,
      consented_to_measurement: consent.gdprPurposes.measurement,
      consented_to_necessary: consent.gdprPurposes.necessary,
    },
  });

  actionPage(client, {
    page: search ? pathname + search.toString() : pathname,
  });

  return client.state;
}

export type consentObject = {
  dateConsented?: string;
  gdprPurposes?: Record<allConsentNames, boolean>;
  origin?: string;
};

export type actionEventConsent =
  | {
      category?: string;
      gdprPurposes: Record<allConsentNames, boolean>;
      options?: EventOptions;
      page?: string;
      props?: EventTypes["page"];
      type: "custom";
    }
  | {
      category?: string;
      gdprPurposes?: never;
      options?: EventOptions;
      page?: string;
      props?: EventTypes["page"];
      type: "all" | "minimum";
    };

export const defaultActionConsent__all: actionEventConsent = {
  type: "all",
};

export const defaultActionConsent__min: actionEventConsent = {
  type: "minimum",
};

export const defaultActionConsent__custom: actionEventConsent = {
  gdprPurposes: {
    experience: false,
    functionality: false,
    marketing: false,
    measurement: true,
    necessary: true,
  },
  type: "custom",
};
