import { useEffect, useMemo, useState } from "react";

import { EventDetective } from "../reducer/eventDetective";
import { DetectiveProps } from "../reducer/types";

export const useDetectiveClient = (options: DetectiveProps) => {
  const [loaded, setLoaded] = useState(false);
  const client = useMemo(() => EventDetective.getOrCreateInstance(options), []);

  useEffect(() => {
    client.addOnLoaded(() => setLoaded(true));
  }, [client]);

  useEffect(() => {
    return () => {
      EventDetective.clearInstance();
    };
  }, []);

  return { client, loaded };
};
