import type { EventOptions, EventTypes } from "@everfund/event-sidekick";

import { EventDetective } from "../eventDetective";
import { getPartialEvent } from "../getPartialEvent";
import { logEvent } from "../queue/logEvent";

export function actionTrack(
  client: InstanceType<typeof EventDetective>,
  action: actionEventTrack,
) {
  const { name, options, properties } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      event: name as string,
      properties: {
        ...partialEvent.properties,
        ...properties,
      },
      type: "track",
    },
  });

  return client.state;
}

export type actionEventTrack = {
  name: keyof EventTypes;
  options?: EventOptions;
  properties: EventTypes[keyof EventTypes];
};

export const defaultActionTrack: actionEventTrack = {
  name: "cta_clicked",
  properties: {
    cta: "button",
    type: "speak-to-agent",
  },
};
