"use client";

import type { allConsentNames } from "@everfund/event-gdpr-types";
import type { Event } from "@everfund/event-sidekick";

import {
  createContext,
  type ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";

import type { ConsentConfigurationOrFlag } from "./reducer/queue/checkConsent";

import { useDetectiveClient } from "./hooks/useDetectiveClient";
import { PageTracker } from "./pageTracker";
import { DEFAULT_SIDEKICK_URL, EventDetective } from "./reducer/eventDetective";

export type ProviderProps = {
  children?: ReactNode | ReactNode[];
  consent: ConsentConfigurationOrFlag;
  debug?: boolean;
  middleware?: (event: Event) => Event | undefined;
  requiredGdprPurposes: allConsentNames[];
  trackOutboundLinks?: boolean;
  trackPageLeave?: boolean;
  trackPageViews?: boolean;
  uploadPath?: string;
};

export const EventDetectiveContext = createContext<
  | { client: EventDetective; loaded: boolean; state: EventDetective["state"] }
  | undefined
>(undefined);

export const EventDetectiveProvider = ({
  children,
  ...rest
}: ProviderProps) => {
  const { client, loaded } = useDetectiveClient({
    consent: rest.consent,
    debug: rest.debug,
    middleware: rest.middleware,
    url: rest.uploadPath ?? DEFAULT_SIDEKICK_URL,
  });

  const [state, setState] = useState<EventDetective["state"]>(client?.state);

  useEffect(() => {
    if (loaded && client) {
      const unsubscribe = client.addListener((updatedState) => {
        setState(updatedState);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [client, loaded]);

  const memoizedValue = useMemo(
    () => ({
      client,
      loaded,
      state,
    }),
    [client, loaded, state],
  );

  return (
    <EventDetectiveContext.Provider value={memoizedValue}>
      <Suspense fallback={null}>
        <PageTracker
          {...{
            trackOutboundLinks: rest.trackOutboundLinks ?? false,
            trackPageLeave: rest.trackPageLeave ?? false,
            trackPageViews: rest.trackPageViews ?? false,
          }}
        />
      </Suspense>
      {children}
    </EventDetectiveContext.Provider>
  );
};
