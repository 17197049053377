export function getDevice(userAgent: string): string {
  if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
    return "Windows Phone";
  }
  if (/iPad/.test(userAgent)) {
    return "iPad";
  }
  if (/iPod/.test(userAgent)) {
    return "iPod Touch";
  }
  if (/iPhone/.test(userAgent)) {
    return "iPhone";
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  }
  if (/Android/.test(userAgent)) {
    return "Android";
  }
  return "";
}
