import { EventDetective } from "../eventDetective";
import { REFERRER_KEY } from "../getPartialEvent/getReferrer";
import { COMMON_PROPERTIES_KEY } from "./common";
import { CONSENT_KEY } from "./consent";
import { GROUP_ID_KEY, GROUP_TRAITS_KEY } from "./group";
import { USER_ID_KEY, USER_TRAITS_KEY } from "./identify";

export function actionReset(client: InstanceType<typeof EventDetective>) {
  client.configStore
    .removeValue(USER_ID_KEY)
    .removeValue(GROUP_ID_KEY)
    .removeValue(USER_TRAITS_KEY)
    .removeValue(GROUP_TRAITS_KEY)
    .removeValue(COMMON_PROPERTIES_KEY)
    .removeValue(CONSENT_KEY)
    .removeValue(REFERRER_KEY);

  client.setState({
    commonProperties: {},
    consent: undefined,
    groupId: undefined,
    groupTraits: undefined,
    referrer: undefined,
    userId: undefined,
    userTraits: undefined,
  });

  return client.state;
}
