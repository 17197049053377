function includes(haystack: string, needle: string): boolean {
  return haystack.indexOf(needle) >= 0;
}

export function getBrowser(
  userAgent: string,
  vendor: string,
  opera: boolean,
): string {
  const localVendor = vendor || ""; // vendor is undefined for at least IE9
  if (opera || includes(userAgent, " OPR/")) {
    if (includes(userAgent, "Mini")) {
      return "Opera Mini";
    }
    return "Opera";
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  }
  if (includes(userAgent, "IEMobile") || includes(userAgent, "WPDesktop")) {
    return "Internet Explorer Mobile";
  }
  if (includes(userAgent, "SamsungBrowser/")) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return "Samsung Internet";
  }
  if (includes(userAgent, "Edge") || includes(userAgent, "Edg/")) {
    return "Microsoft Edge";
  }
  if (includes(userAgent, "FBIOS")) {
    return "Facebook Mobile";
  }
  if (includes(userAgent, "Chrome")) {
    return "Chrome";
  }
  if (includes(userAgent, "CriOS")) {
    return "Chrome iOS";
  }
  if (includes(userAgent, "UCWEB") || includes(userAgent, "UCBrowser")) {
    return "UC Browser";
  }
  if (includes(userAgent, "FxiOS")) {
    return "Firefox iOS";
  }
  if (includes(localVendor, "Apple")) {
    if (includes(userAgent, "Mobile")) {
      return "Mobile Safari";
    }
    return "Safari";
  }
  if (includes(userAgent, "Android")) {
    return "Android Mobile";
  }
  if (includes(userAgent, "Konqueror")) {
    return "Konqueror";
  }
  if (includes(userAgent, "Firefox")) {
    return "Firefox";
  }
  if (includes(userAgent, "MSIE") || includes(userAgent, "Trident/")) {
    return "Internet Explorer";
  }
  if (includes(userAgent, "Gecko")) {
    return "Mozilla";
  }
  return "";
}
