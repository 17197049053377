import type { Event, UploadRequest } from "@everfund/event-sidekick";

import pkg from "../../../package.json";
import { DEFAULT_SIDEKICK_URL } from "../eventDetective";
import { EventDetective } from "../eventDetective";

let eventQueue: Event[] = [];
let debounceTimeout: NodeJS.Timeout | null = null;

type addToQueueProps = {
  client: InstanceType<typeof EventDetective>;
  event: Event;
};

export const addToEventQueue = ({ client, event }: addToQueueProps) => {
  eventQueue.push(event);

  client.logDebug("[EventQueue Debug] Event added to queue", event);

  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  debounceTimeout = setTimeout(() => {
    const data: UploadRequest = {
      events: eventQueue,
      sentAt: new Date(),
      version: pkg.version,
    };

    // Deduplicate the events before sending the upload request
    const dedupeData = deduplicateEvents(data, client);

    if (client.options.debug) {
      client.logDebug(
        "[EventQueue Debug] Sending events to server",
        dedupeData,
      );
    }

    window
      .fetch(client.options?.url ?? DEFAULT_SIDEKICK_URL, {
        body: JSON.stringify(dedupeData),
        headers: {
          "Content-Type": "text/plain",
          "X-Debug": client.options.debug ? "true" : "false",
        },
        method: "POST",
      })
      .then((response) => {
        client.logDebug("[EventQueue Debug] Server response", response);
      })
      .catch((error) => {
        client.logDebug("[EventQueue Debug] Error sending events", error);
      });

    eventQueue = [];
    debounceTimeout = null;
  }, client.options.eventDebounceInterval);

  return debounceTimeout;
};

export function deduplicateEvents(
  uploadRequest: UploadRequest,
  client: InstanceType<typeof EventDetective>,
): UploadRequest {
  const uniqueEvents: Record<string, Event> = {};
  const originalCount = uploadRequest.events.length;

  for (const event of uploadRequest.events) {
    const eventKey =
      event.name ?? event.event
        ? `${event.type}-${event.name ?? event.event}${JSON.stringify(event.properties)}-${event.timestamp}`
        : `${event.type}-${JSON.stringify(event.properties)}-${event.timestamp}`;

    uniqueEvents[eventKey] = event;
  }

  const dedupeEvents = Object.values(uniqueEvents);
  const dedupeCount = dedupeEvents.length;

  client.logDebug("[Dedupe Debug] Events deduplication", {
    dedupeCount,
    duplicatesRemoved: originalCount - dedupeCount,
    originalCount,
  });

  return {
    ...uploadRequest,
    events: dedupeEvents,
  };
}
