"use client";

import { useEffect } from "react";

import { location, window } from "../utils/globals";
import { useAnalytics } from "./useAnalytics";

type UsePageLeaveEventProps = {
  enabled:
    | {
        outboundLinks?: boolean;
        pageLeave?: boolean;
      }
    | false
    | null
    | undefined;
};

export function usePageLeaveEvent({ enabled }: UsePageLeaveEventProps): void {
  const { track } = useAnalytics();

  useEffect(() => {
    if (enabled === false || enabled === undefined || enabled === null) {
      return;
    }

    const targetNode = document;
    const observerInit = {
      attributeFilter: ["href"],
      attributes: true,
      childList: true,
      subtree: true,
    };

    function trackClick(this: HTMLAnchorElement, event: MouseEvent): void {
      // Check if the click event is inside the child div of the element with ID "crisp-chatbox"
      if (
        event.target instanceof HTMLElement &&
        event.target.closest("#crisp-chatbox")
      ) {
        return; // Ignore the click event if it's inside the "crisp-chatbox" child div
      }

      const pathname = location?.pathname;
      const search = location?.search;
      track({
        name: "$pageleave",
        properties: {
          $prev_pageview_pathname: search
            ? pathname + search.toString()
            : pathname,
          outbound_link_url: this.href,
          type: "outbound_link",
        },
      });

      if (
        !(typeof process !== "undefined" && process.env.NODE_ENV === "test")
      ) {
        setTimeout(() => {
          if (location?.href) {
            location.href = this.href;
          }
        }, 200);
      }
      event.preventDefault();
    }

    const tracked = new Set<HTMLAnchorElement>();

    function addNode(node: Node | ParentNode): void {
      if (node instanceof HTMLAnchorElement) {
        if (node.host !== location?.host) {
          node.addEventListener("click", trackClick);
          tracked.add(node);
        }
      } else if ("querySelectorAll" in node) {
        node.querySelectorAll("a").forEach(addNode);
      }
    }

    function removeNode(node: Node | ParentNode): void {
      if (node instanceof HTMLAnchorElement) {
        node.removeEventListener("click", trackClick);
        tracked.delete(node);
      } else if ("querySelectorAll" in node) {
        node.querySelectorAll("a").forEach(removeNode);
      }
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          // Handle changed href
          removeNode(mutation.target);
          addNode(mutation.target);
        } else if (mutation.type === "childList") {
          // Handle added nodes
          mutation.addedNodes.forEach(addNode);
          // Handle removed nodes
          mutation.removedNodes.forEach(removeNode);
        }
      });
    });

    if (typeof enabled === "object" && enabled.outboundLinks) {
      // Track existing nodes
      targetNode.querySelectorAll("a").forEach(addNode);
      // Observe mutations
      observer.observe(targetNode, observerInit);
    }

    function trackTabClose(event: BeforeUnloadEvent): void {
      // Check if the tab close event is inside the child div of the element with ID "crisp-chatbox"
      if (
        event.target instanceof HTMLElement &&
        event.target.closest("#crisp-chatbox")
      ) {
        return; // Ignore the tab close event if it's inside the "crisp-chatbox" child div
      }

      const pathname = location?.pathname;
      const search = location?.search;
      track({
        name: "$pageleave",
        properties: {
          $prev_pageview_pathname: search
            ? pathname + search.toString()
            : pathname,
          type: "tab_close",
        },
      });

      if (
        !(typeof process !== "undefined" && process.env.NODE_ENV === "test")
      ) {
        setTimeout(() => {
          // Keep the setTimeout code here
        }, 200);
      }
    }

    if (typeof enabled === "object" && enabled.pageLeave) {
      window?.addEventListener("beforeunload", trackTabClose);
    }

    return () => {
      tracked.forEach((a) => {
        a.removeEventListener("click", trackClick);
      });
      tracked.clear();
      observer.disconnect();
      window?.removeEventListener("beforeunload", trackTabClose);
    };
  }, [enabled, track]);
}
