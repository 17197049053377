/*
 * Global helpers to protect access to browser globals in a way that is safer for different targets
 * like DOM, SSR, Web workers etc.
 *
 * NOTE: Typically we want the "window" but globalThis works for both the typical browser context as
 * well as other contexts such as the web worker context. Window is still exported for any bits that explicitly require it.
 * If in doubt - export the global you need from this file and use that as an optional value. This way the code path is forced
 * to handle the case where the global is not available.
 */
const win: (typeof globalThis & Window) | undefined =
  typeof window !== "undefined" ? window : undefined;

const global: typeof globalThis | undefined =
  typeof globalThis !== "undefined" ? globalThis : win;

export const navigator = global?.navigator;
export const document = global?.document;
export const location = global?.location;
export const userAgent = navigator?.userAgent;

export const assignableWindow: Record<string, any> &
  typeof globalThis &
  Window = win ?? ({} as any);

export function searchParams(search: string): undefined | URLSearchParams {
  if (search === "" || search == null) {
    return undefined;
  }
  return new URLSearchParams(search);
}

export { win as window };
