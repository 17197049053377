export function getOS(
  window: (typeof globalThis & Window) | undefined,
): string {
  const a = window?.navigator.userAgent;
  if (!a) {
    return "";
  }
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return "Windows Phone";
    }
    return "Windows";
  }
  if (/(iPhone|iPad|iPod)/.test(a)) {
    return "iOS";
  }
  if (/Android/.test(a)) {
    return "Android";
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return "BlackBerry";
  }
  if (/Mac/i.test(a)) {
    return "Mac OS X";
  }
  if (/Linux/.test(a)) {
    return "Linux";
  }
  if (/CrOS/.test(a)) {
    return "Chrome OS";
  }
  return "";
}
