import type { EventOptions } from "@everfund/event-sidekick";

import { EventDetective } from "../eventDetective";
import { getPartialEvent } from "../getPartialEvent";
import { logEvent } from "../queue/logEvent";

export function actionAlias(
  client: InstanceType<typeof EventDetective>,
  action: actionEventAlias,
) {
  const { from, options, to } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      context: {
        ...partialEvent.context,
        traits: undefined,
      },
      previousId: from,
      type: "alias",
      userId: to,
    },
  });

  return client.state;
}

export type actionEventAlias = {
  from?: string;
  options?: EventOptions;
  to: string;
};

export const defaultActionAlias: actionEventAlias = {
  from: "u_124",
  to: "u_123",
};
