import type { EventOptions, EventTypes } from "@everfund/event-sidekick";

import { EventDetective } from "../eventDetective";
import { getPartialEvent } from "../getPartialEvent";
import { logEvent } from "../queue/logEvent";

export function actionPage(
  client: InstanceType<typeof EventDetective>,
  action: actionEventPage,
) {
  const { category, options, page, props } = action;

  const partialEvent = getPartialEvent({ client, options });

  logEvent({
    client,
    event: {
      ...partialEvent,
      name: page,
      properties: {
        ...partialEvent.context.page,
        ...partialEvent.properties,
        ...props,
        category,
      },
      type: "page",
    },
  });

  return client.state;
}

export type actionEventPage = {
  category?: string;
  options?: EventOptions;
  page?: string;
  props?: EventTypes["page"];
};

export const defaultActionPage: actionEventPage = {
  category: "one",
  page: "oldId",
};
