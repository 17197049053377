import type { Event } from "@everfund/event-sidekick";

import { window } from "../../utils/globals";
import { EventDetective } from "../eventDetective";
import { addToEventQueue } from "./addToEventQueue";
import { isDataCollectionAllowed } from "./checkConsent";

export type LogEventProps = {
  client: InstanceType<typeof EventDetective>;
  event: Event;
};

export function logEvent({ client, event }: LogEventProps) {
  let localEvent: Event | undefined = event;

  if (client.options.middleware != null) {
    client.logDebug("adding middleware to event");
    localEvent = client.options.middleware(localEvent);
  }

  if (localEvent == null || localEvent === undefined) {
    return client.logDebug("Event is not defined");
  }

  const canSendEvent = isDataCollectionAllowed(client);

  client.logDebug("canSendEvent", canSendEvent);

  if (canSendEvent) {
    window?.dispatchEvent(
      new CustomEvent("everfund-event", {
        detail: event,
      }),
    );

    addToEventQueue({ client, event: localEvent });
  }
}
