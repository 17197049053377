import Cookies from "js-cookie";

import type { COMMON_PROPERTIES_KEY } from "../actions/common";
import type { CONSENT_KEY } from "../actions/consent";
import type { GROUP_ID_KEY, GROUP_TRAITS_KEY } from "../actions/group";
import type {
  ANONYMOUS_ID_KEY,
  USER_ID_KEY,
  USER_TRAITS_KEY,
} from "../actions/identify";
import type { REFERRER_KEY } from "../getPartialEvent/getReferrer";

import { window } from "../../utils/globals";

type ConfigStoreKey =
  | typeof ANONYMOUS_ID_KEY
  | typeof COMMON_PROPERTIES_KEY
  | typeof CONSENT_KEY
  | typeof GROUP_ID_KEY
  | typeof GROUP_TRAITS_KEY
  | typeof REFERRER_KEY
  | typeof USER_ID_KEY
  | typeof USER_TRAITS_KEY;

export type IConfigStore = {
  getValue: <T>(key: ConfigStoreKey | string) => null | T | undefined | unknown;
  removeValue: (key: ConfigStoreKey | string) => IConfigStore;
  setValue: (key: ConfigStoreKey | string, value: unknown) => IConfigStore;
};

type localKey = ConfigStoreKey | string;

export function createUniversalConfigStore(
  namespace: string,
  stores: IConfigStore[] = [],
): IConfigStore {
  let localStores = stores;
  if (stores.length === 0) {
    if (window) {
      localStores = [
        setInMemoryValue(),
        setCookieValue(),
        setLocalStorageValue(),
      ];
    } else {
      localStores = [setInMemoryValue()];
    }
  }

  function setValue(this: IConfigStore, key: localKey, value: unknown) {
    const namespaceKey = `${namespace}.${key}`;
    for (const store of localStores) {
      store.setValue(namespaceKey, value);
    }
    return this;
  }

  function getValue(key: localKey) {
    const namespaceKey = `${namespace}.${key}`;
    const missedStores: IConfigStore[] = [];
    for (const store of localStores) {
      const value = store.getValue(namespaceKey);
      if (value !== undefined) {
        for (const missedStore of missedStores) {
          missedStore.setValue(namespaceKey, value);
        }
        return value;
      }
      missedStores.push(store);
    }
    return undefined;
  }

  function removeValue(this: IConfigStore, key: localKey) {
    const namespaceKey = `${namespace}.${key}`;
    for (const store of localStores) {
      store.removeValue(namespaceKey);
    }
    return this;
  }

  return {
    getValue,
    removeValue,
    setValue,
  };
}

function setLocalStorageValue(): IConfigStore {
  function setValue(this: IConfigStore, key: localKey, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
    return this;
  }

  function getValue(key: localKey) {
    const strValue = localStorage.getItem(key);
    if (strValue != null) {
      return JSON.parse(strValue);
    }
    return undefined;
  }

  function removeValue(this: IConfigStore, key: localKey) {
    localStorage.removeItem(key);
    return this;
  }

  return {
    getValue,
    removeValue,
    setValue,
  };
}

function setCookieValue(): IConfigStore {
  function setValue(this: IConfigStore, key: localKey, value: unknown) {
    Cookies.set(key, JSON.stringify(value));
    return this;
  }

  function getValue(key: localKey) {
    const strValue = Cookies.get(key);
    if (strValue != null) {
      return JSON.parse(strValue);
    }
  }

  function removeValue(this: IConfigStore, key: localKey) {
    Cookies.remove(key);
    return this;
  }

  return {
    getValue,
    removeValue,
    setValue,
  };
}

function setInMemoryValue(): IConfigStore {
  const inMemory = new Map<string, unknown>();

  function setValue(this: IConfigStore, key: localKey, value: unknown) {
    inMemory.set(key, value);
    return this;
  }

  function getValue(key: localKey) {
    return inMemory.get(key) as unknown;
  }

  function removeValue(this: IConfigStore, key: localKey) {
    inMemory.delete(key);

    return this;
  }

  return {
    getValue,
    removeValue,
    setValue,
  };
}

export const configStore = createUniversalConfigStore("everfund", []);
